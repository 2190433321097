/**
 * Подождать, пока predicateFn не вернёт truthy значение
 * Можно использовать для блокировки контекста выполнения функции
 * @param {Function} predicateFn
 * @param {{timeout: number, tick: number}} opts
 * @returns {Promise}
 */
export const waitFor = (predicateFn, opts = {}) => {
  const { timeout = 5000, tick = 50 } = opts;

  return new Promise((resolve, reject) => {
    let tickTimer = 0;
    let timeoutTimer = 0;

    const clear = () => {
      clearTimeout(tickTimer);
      clearTimeout(timeoutTimer);
    };

    const onSuccess = result => {
      clear();
      resolve(result);
    };

    const onError = reason => {
      clear();
      reject(new Error(reason));
    };

    const tickFn = async () => {
      const result = await predicateFn();

      if (result) {
        onSuccess(result);
      } else {
        tickTimer = setTimeout(tickFn, tick);
      }
    };

    if (typeof predicateFn !== 'function') {
      onError('predicateFn must be a function');
      return;
    }

    timeoutTimer = setTimeout(() => onError('timeout'), timeout);
    tickFn();
  });
};
