import { http } from 'src/apps/product-detail/utils/http';

class ShippingAPI {
  getMethods = (opts = {}) => {
    const { cityId, productId } = opts;

    return http.get(`/api/shipping/methods/${cityId}/${productId}`).then(r => r.data);
  };
}

export const shippingAPI = new ShippingAPI();
