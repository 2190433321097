import { http } from 'src/apps/product-detail/utils/http';

class SpecialOffers {
  getSpecialOffers = async () => {
    const params = ['id', 'image', 'cleanURL', 'title'];
    try {
      const {
        data: { data },
      } = await http.get('/api/content/special-offers', { select: params.join(',') });
      return data;
    } catch (err) {
      console.warn(err);
      throw err;
    }
  };

  getSpecialOffer = async id => {
    try {
      const { data } = await http.get(`/api/content/special-offers/${id}`);
      return data;
    } catch (err) {
      console.warn(err);
      throw err;
    }
  };
}

export const specialOffers = new SpecialOffers();
