import { http } from 'src/apps/product-detail/utils/http';

class ContextAPI {
  setContext = () => {
    return http.post('/api/contexts');
  };

  addItem = (opts = {}) => {
    const { contextId, productId, attributeValues, amount } = opts;

    return http.post(`/api/contexts/${contextId}/items`, { productId, attributeValues, amount });
  };

  fetchRecommendedProduct = ({ productId, limit }) => {
    return http.get(`/api/products/${productId}/recommended/top`, { limit });
  };

  deleteItem = (opts = {}) => {
    const { contextId, itemId } = opts;

    return http.delete(`/api/contexts/${contextId}/items/${itemId}`);
  };
}

export const contextAPI = new ContextAPI();
