import { profileAPI, authAPI } from 'src/api';
import { waitFor } from 'src/utils/wait-for';
import * as persistSmsVerification from 'src/features/persist-sms-verification';
import * as Sentry from '@sentry/vue';
import { ERROR_CODE } from 'src/constants';

export const auth = {
  namespaced: true,

  state: () => ({
    loading: false,
    socialMethods: [],
    availableAuthMethods: [],
    recaptchaToken: null,
    errorVerify: '',
  }),
  mutations: {
    setLoading: (state, loading) => {
      globalThis.emitter.emit('showPreloader', loading);
    },
    setSocialMethods(state, payload) {
      state.socialMethods = payload;
    },
    setAvailableAuthMethods(state, payload) {
      state.availableAuthMethods = payload;
    },
    setRecaptchaToken: (state, payload) => {
      state.recaptchaToken = payload;
    },
    setErrorVerify: (state, payload) => {
      state.errorVerify = payload;
    },
  },
  actions: {
    async loadSocialMethods({ commit }) {
      const socialMethods = await authAPI.getSocialMethods();
      commit('setSocialMethods', socialMethods);
    },
    async loadAvailableAuthMethods({ commit }) {
      commit('setLoading', true);
      try {
        const availableAuthMethods = await authAPI.getMethod();
        commit('setAvailableAuthMethods', availableAuthMethods);
      } finally {
        commit('setLoading', false);
      }
    },
    async getRecaptchaToken({ commit }) {
      if (window.grecaptcha) {
        await waitFor(() => window.grecaptcha);
      }

      return new Promise((resolve, reject) => {
        if (window.grecaptcha) {
          // eslint-disable-line
          // eslint-disable-next-line no-undef
          grecaptcha.ready(() => {
            // eslint-disable-line
            // eslint-disable-next-line no-undef
            grecaptcha
              // eslint-disable-next-line no-underscore-dangle
              .execute(window.__X_CART_DATA__.reCaptchaKey, { action: 'default' })
              .then(token => {
                commit('setRecaptchaToken', token);
                resolve(token);
              })
              .catch(e => {
                reject(e);
              });
          });
        } else {
          resolve(null);
        }
      });
    },
    async generate({ commit, dispatch, getters }, { type, login }) {
      commit('setLoading', true);
      await dispatch('getRecaptchaToken').finally(async () => {
        try {
          const payload = {
            type,
            login,
            captcha: getters.recaptchaToken,
          };
          /* eslint-disable camelcase */
          const { has_sms } = await authAPI.generate(payload);
          persistSmsVerification.maybeNotify(has_sms, type);
        } catch (e) {
          if (e?.response && e?.response?.status === ERROR_CODE.forbidden) {
            globalThis.emitter.emit(
              'addNotification',
              'Вы будете перенаправлены на подтверждение статуса через 5 секунд',
            );
            Sentry.captureException(new Error('403 response from generation request'), {
              tags: {
                section: 'generate',
              },
            });
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          } else {
            const msg = e.message || e.response?.data?.message || 'Произошла ошибка';
            globalThis.emitter.emit('addNotification', msg);

            Sentry.captureException(new Error(msg), {
              tags: {
                section: 'generate',
              },
            });

            throw e;
          }
        } finally {
          commit('setRecaptchaToken', null);
          commit('setLoading', false);
        }
      });
    },
    async verify({ state, commit }, { type, login, code }) {
      commit('setLoading', true);
      commit('setErrorVerify', '');
      try {
        const payload = {
          type,
          login,
          code,
        };
        const { regAuth, profile } = await authAPI.verify(payload);

        if (regAuth) {
          profileAPI.setRegAuth({ id: state.context.id });

          window.xcore.trigger('analyticsRegister', {
            profile: profile.profile_id,
          });
        } else {
          window.xcore.trigger('analyticsAuth', {
            profile: profile.profile_id,
          });
        }
      } catch (e) {
        if (!e.response?.data?.message) {
          commit('setErrorVerify', 'Произошла ошибка');
        } else {
          commit('setErrorVerify', e.response.data.message);
        }
        throw e;
      } finally {
        commit('setLoading', false);
      }
    },
    async login({ commit, dispatch, getters }, { login, password }) {
      commit('setLoading', true);
      try {
        await dispatch('getRecaptchaToken');

        const payload = {
          login,
          password,
          captcha: getters.recaptchaToken,
        };
        const { profile } = await authAPI.login(payload);

        window.xcore.trigger('analyticsAuth', {
          profile: profile.profile_id,
        });
        window.xcore.trigger('analyticsGtagEvent', {
          event: 'event_name',
          params: {
            event_category: 'email_entrance',
            event_action: 'email_entrance',
          },
        });
        window.xcore.trigger('analyticsGtagEvent', {
          event: 'event_name',
          params: {
            event_category: 'password_entrance',
            event_action: 'password_entrance',
          },
        });
      } catch (e) {
        if (!e.response?.data?.message) {
          globalThis.emitter.emit('addNotification', 'Произошла ошибка');
        } else {
          globalThis.emitter.emit('addNotification', e.response.data.message);
        }
        throw e;
      } finally {
        commit('setRecaptchaToken', null);
        commit('setLoading', false);
      }
    },
    async restoreEmail({ commit, dispatch, getters }, { email, target }) {
      commit('setLoading', true);
      await dispatch('getRecaptchaToken').finally(async () => {
        try {
          const payload = {
            login: email,
            captcha: getters.recaptchaToken,
            target,
          };
          await authAPI.restoreEmail(payload);
        } catch (e) {
          if (!e.response?.data?.message) {
            globalThis.emitter.emit('addNotification', 'Произошла ошибка');
          } else {
            globalThis.emitter.emit('addNotification', e.response.data.message);
          }
          throw e;
        } finally {
          commit('setRecaptchaToken', null);
          commit('setLoading', false);
        }
      });
    },
    async verifyEmail({ commit, dispatch, getters }, { email, code, password, confirmPassword }) {
      commit('setLoading', true);
      globalThis.emitter.emit('addNotification', '');
      await dispatch('getRecaptchaToken').finally(async () => {
        try {
          const payload = {
            login: email,
            code,
            captcha: getters.recaptchaToken,
            password,
            confirmPassword,
          };
          await authAPI.verifyEmail(payload);
        } catch (e) {
          if (!e.response?.data?.message) {
            globalThis.emitter.emit('addNotification', 'Произошла ошибка');
          } else {
            globalThis.emitter.emit('addNotification', e.response.data.message);
          }
          throw e;
        } finally {
          commit('setRecaptchaToken', null);
          commit('setLoading', false);
        }
      });
    },
  },
  getters: {
    socialMethods(state) {
      return state.socialMethods;
    },
    availableAuthMethods(state) {
      return state.availableAuthMethods;
    },
    loading: state => state.loading,
    recaptchaToken: state => state.recaptchaToken,
    errorVerify(state) {
      return state.errorVerify;
    },
    priorityMethod(state) {
      return state.availableAuthMethods[0];
    },
  },
};
