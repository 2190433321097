export function getAttributes(node) {
  const attributeNodes = node.attributes;
  const { length } = attributeNodes;
  const attrs = {};

  for (let i = 0; i < length; i++) {
    attrs[attributeNodes[i].name] = attributeNodes[i].value;
  }

  return attrs;
}
