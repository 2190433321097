// Обёртка над работой с HTTP запросами

import Vue from 'vue';
import qs from 'qs';

class Http {
  get = (_path, params, options) => {
    const path = params ? _path + qs.stringify(params, { addQueryPrefix: true }) : _path;

    return Vue.$http.get(path, options);
  };

  post = (path, params = {}, options = {}) => {
    return Vue.$http.post(path, params, options);
  };

  patch = (path, params = {}, options = {}) => {
    return Vue.$http.patch(path, params, options);
  };

  delete = (path, params = {}, options = {}) => {
    return Vue.$http.delete(path, { ...options, data: params });
  };
}

export const http = new Http();
