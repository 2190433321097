import { onPointSelect, sendAnalytics } from '../../../utils/analytics';

export default {
  state: () => ({
    showPopupError: false,
    innerLoading: false,
    error: null,
    modalType: 'half',
    contactEditMode: 'VIEW',
    noConfirmedPhone: '',
    isPhoneChanged: false,
    selectedAddress: {},
    changedPickupPoint: {},
    isCitySelection: false,
    mapCoords: [],
    mapZoom: 10,
    constantZoomCity: 10,
    constantZoomAddress: 18,
    deletedItems: [],
    showPriceChangeAlert: false,
  }),
  mutations: {
    setShowPopupError(state, payload) {
      state.showPopupError = payload;
    },
    setInnerLoading(state, payload) {
      state.innerLoading = payload;
    },
    setError(state, payload) {
      state.error = payload;
    },
    clearError(state) {
      state.error = null;
    },
    setModalType(state, payload) {
      state.modalType = payload;
    },
    setSelectedAddress(state, payload) {
      state.selectedAddress = payload;
    },
    setChangedPickupPoint(state, payload) {
      state.changedPickupPoint = payload;
    },
    setCitySelection(state, payload) {
      state.isCitySelection = payload;
    },
    setContactEditMode(state, payload) {
      state.contactEditMode = payload;
    },
    setNoConfirmedPhone(state, payload) {
      state.noConfirmedPhone = payload;
    },
    setIsPhoneChanged(state, payload) {
      state.isPhoneChanged = payload;
    },
    setMapCoords(state, payload) {
      state.mapCoords = payload;
    },
    setMapZoom(state, payload) {
      state.mapZoom = payload;
    },
    setDeletedItems(state, payload) {
      state.deletedItems.splice(0, state.deletedItems.length, ...payload);
    },
    addDeletedItem(state, payload) {
      const index = state.deletedItems.findIndex(item => item.itemId === payload.itemId);
      if (index !== -1) {
        state.deletedItems.splice(index, 1);
      }
      state.deletedItems.push({
        ...payload,
        time: new Date().getTime(),
        deleting: true,
      });
      window.localStorage.setItem('deletedItems', JSON.stringify(state.deletedItems));
    },
    removeDeletedItem(state, payload) {
      const index = state.deletedItems.findIndex(item => item.itemId === payload.itemId);
      if (index !== -1) {
        state.deletedItems.splice(index, 1);
        window.localStorage.setItem('deletedItems', JSON.stringify(state.deletedItems));
      }
    },
    setShowPriceChangeAlert(state, payload) {
      state.showPriceChangeAlert = payload;
    },
  },
  actions: {
    showPopupError(commit, payload) {
      commit('setShowPopupError', payload);
    },
    setInnerLoading({ commit }, payload) {
      commit('setInnerLoading', payload);
    },
    setError({ commit }, payload) {
      commit('setError', payload);
    },
    clearError({ commit }) {
      commit('clearError');
    },
    setModalType({ commit }, payload) {
      commit('setModalType', payload);
    },
    setSelectedAddress({ commit }, payload) {
      commit('setSelectedAddress', payload);
    },
    setChangedPickupPoint({ commit }, payload) {
      onPointSelect(payload.type);
      commit('setChangedPickupPoint', payload);
    },
    setCitySelection({ commit }, payload) {
      sendAnalytics('delivery_town', {
        category: 'delivery',
        action: 'town',
      });
      commit('setCitySelection', payload);
    },
    setContactEditMode({ commit }, payload) {
      commit('setContactEditMode', payload);
    },
    setNoConfirmedPhone({ commit }, payload) {
      commit('setNoConfirmedPhone', payload);
    },
    setIsPhoneChanged({ commit }, payload) {
      commit('setIsPhoneChanged', payload);
    },
    setMapCoords({ commit }, payload) {
      commit('setMapCoords', payload);
    },
    setMapZoom({ commit }, payload) {
      commit('setMapZoom', payload);
    },
    showPriceChangeAlert(commit, payload) {
      commit('setShowPriceChangeAlert', payload);
    },
  },
  getters: {
    innerLoading(state) {
      return state.innerLoading;
    },
    error(state) {
      return state.error;
    },
    modalType(state) {
      return state.modalType;
    },
    selectedAddress(state) {
      return state.selectedAddress;
    },
    changedPickupPoint(state) {
      return state.changedPickupPoint;
    },
    isCitySelection(state) {
      return state.isCitySelection;
    },
    contactEditMode(state) {
      return state.contactEditMode;
    },
    noConfirmedPhone(state) {
      return state.noConfirmedPhone;
    },
    isPhoneChanged(state) {
      return state.isPhoneChanged;
    },
    constantZoomCity(state) {
      return state.constantZoomCity;
    },
    constantZoomAddress(state) {
      return state.constantZoomAddress;
    },
  },
};
