export function cdn(path) {
  const cdnHost = globalThis?.xliteConfig?.cdnHost || '';
  if (cdnHost) {
    return `https://${cdnHost}${path || ''}`;
  }
  return `${path || ''}`;
}
export function preview(path, opts = {}) {
  const { width, height } = opts;

  return `${path}`.replace('{width}', width || 'x').replace('{height}', height || 'x');
}
export function srcset(path) {
  // list of sizes from https://medium.com/hceverything/applying-srcset-choosing-the-right-sizes-for-responsive-images-at-different-breakpoints-a0433450a4a3
  return [
    `${preview(path, { width: 640 })} 640w`,
    `${preview(path, { width: 768 })} 768w`,
    `${preview(path, { width: 1024 })} 1024w`,
    `${preview(path, { width: 1366 })} 1366w`,
    `${preview(path, { width: 1600 })} 1600w`,
  ].join(',');
}
