const adjustTooltipPosition = (e, tooltipClass) => {
  const tooltip = e.currentTarget.querySelector(tooltipClass);
  if (!tooltip) {
    return;
  }
  const { left, right } = tooltip.getBoundingClientRect();

  if (left < 0) {
    tooltip.style.cssText = `
      left: 0px;
      transform: none;
    `;
  } else if (right > window.innerWidth) {
    tooltip.style.cssText = `
      right: 0px;
      left: auto;
      transform: none;
    `;
  }
};

export { adjustTooltipPosition };
