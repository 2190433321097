// события аналитики

export const ANALYTICS = {
  HEAD: 'analyticsHead', // головной, общий
  PRODUCT_DETAIL: 'analyticsProduct', // на детальной странице продукта
  SEARCH: 'analyticsSearch', // поиск
  AUTH: 'analyticsAuth', // авторизация
  REGISTER: 'analyticsRegister', // регистрация
  ITEM_ADD: 'analyticsAddItemToCart', // добавление в корзину
  ITEM_REMOVE: 'analyticsRemoveItemFromCart', // удаление из корзины
  TRANSACTION: 'analyticsTransaction', // транзакция
  CART: 'analyticsCart', // Корзина
  PROGRESS: 'analyticsProgressFromCart', // шаги Корзины
  GTAG_EVENT: 'analyticsGtagEvent', // событие gtag
  BEGIN_CHECKOUT: 'analyticsBeginCheckout', // start checkout process
  SHIPPING_CHECKOUT: 'analyticsShippingCheckout',
  CATALOG: 'analyticsCatalog',
  SUCCESS_CHECKOUT: 'analyticsSuccessCheckout',
  ADD_PAYMENT_INFO: 'analyticsAddPaymentInfo',
  ITEM_ADD_SUCCESS: 'SuccessfullyAdded',
  BILLING_CHECKOUT: 'analyticsBillingCheckout',
  APP_STORE: 'analyticsAppstore',
  GOOGLE_PLAY: 'analyticsGoogleplay',
  CALLBACK_FORM: 'analyticsCallbackForm',
  CONTACT_US_FORM: 'analyticsContactUsForm',
  SEND_GOOGLE_EVENT: 'analyticsSendGoogleEvent',
  FORM_SALON: 'analyticsFormSalon',
  FORM_SPECIALIST: 'analyticsFormSpecialist',
  FORM_DATE: 'analitycsFormDate',
  GALERY_CLICK: 'analyticsGalleryClick',
  BANNER: 'analyticsBanner',
  PRODUCT_CLICK: 'analyticsProductClick',
  PRODUCT_PROMOTION_CLICK: 'analyticsProductPromotionClick',
  BANNER_CLICK: 'analyticsBannerClick',
  MEDICAL_APPOINTMENT_SALE: 'analyticsMedicalAppointmentSale',
  MEDICAL_APPOINTMENT: 'analyticsMedicalAppointment',
  MEDICAL_APPOINTMENT_WATCH: 'analyticsMedicalAppointmentWatch',
  MEDICAL_APPOINTMENT_START: 'analyticsMedicalAppointmentStart',
  NO_LK_CHECKOUT: 'analyticsNoLKCheckout',
  REVIEW_SEND: 'analyticsReviewSend',
  COMPARE: 'analyticsCompare',
  DOCTOR_ENTRY_SEND: 'analyticsDoctorEntrySend',
  MAIN_PAGE: 'analyticsMainPage',
  PRODUCT_VIEW: 'productView',
  LISTING_VIEW: 'listingView',
  PRODUCT_SALE: 'productSale',
  CART_CHECK: 'cartCheck',
  START_ORDER: 'startOrder',
  OTHER_PAGE: 'otherPage',
  SHOW_PRODUCT: 'showProduct', // показ товаров для ecommerce,
  BUY_ONE_CLICK: 'buyOneClick',
  PHONE_ENTRY_CHECKOUT: 'phoneEntryCheckout',
  CODE_ENTRY_CHECKOUT: 'codeEntryCheckout',
};

export const ANALYTICS_STEP_CART = {
  shipping: {
    category: 'step1',
    event: 'my_order',
    progress: 'checkout_progress1',
  },
  payment: {
    category: 'step2',
    event: 'delivery',
    progress: 'checkout_progress2',
  },
  billing: {
    category: 'step3',
    event: 'pay',
    progress: 'checkout_progress3',
  },
  finish: {
    category: 'step4',
    event: 'pay',
    progress: 'checkout_progress4',
  },
};
