export const idForAnalytics = (productId, variantIds = []) => {
  const product = `p${productId}`;

  const uniqVariants = variantIds.filter((variant, i, self) => self.indexOf(variant) === i);
  const variants = [];
  uniqVariants.forEach(variant => {
    if (variant) {
      variants.push(`v${variant}`);
    }
  });

  if (variants.length) {
    return variants.map(variant => product + variant);
  }
  return [product];
};
