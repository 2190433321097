let startX = 0;
let startY = 0;

export const handleTouchStart = e => {
  startX = e.changedTouches[0].screenX;
  startY = e.changedTouches[0].screenY;
};

export const handleTouchEnd = e => {
  const diffX = e.changedTouches[0].screenX - startX;
  const diffY = e.changedTouches[0].screenY - startY;
  const ratioX = Math.abs(diffX / diffY);
  const ratioY = Math.abs(diffY / diffX);
  const absDiff = Math.abs(ratioX > ratioY ? diffX : diffY);
  if (absDiff < 30) {
    return null;
  }

  if (ratioX > ratioY) {
    if (diffX >= 0) {
      return 'right';
    }
    return 'left';
  }
  if (diffY >= 0) {
    return 'down';
  }
  return 'up';
};
