import { http } from 'src/apps/product-detail/utils/http';

class Html {
  getCallUs() {
    return http
      .get(
        '/?target=call_us&widget=%5CXLite%5CModule%5CItech%5CCallUs%5CView%5CCallUs',
        {},
        {
          data: {},
          headers: {
            Accept: '*/*',
            'Content-Type': 'text/html',
            'X-Requested-With': 'XMLHttpRequest',
          },
        },
      )
      .then(r => r.data);
  }

  getDoctor() {
    return http
      .get(
        '/?target=medical_record_select_type&widget=%5CXLite%5CModule%5CItech%5CMedicalRecord%5CView%5CSelectRecordType',
        {},
        {
          data: {},
          headers: {
            Accept: '*/*',
            'Content-Type': 'text/html',
            'X-Requested-With': 'XMLHttpRequest',
          },
        },
      )
      .then(r => r.data);
  }
}

export const html = new Html();
