/**
 * Проверяет, мобильная ли версия сайта сейчас открыта.
 * Брейкпоинт взял из легаси кода.
 *
 * @returns {boolean}
 */
export function isMobile() {
  return window.innerWidth < 1250;
}

/**
 * Проверяет, мобильное ли устройство.
 *
 * @returns {boolean}
 */
export function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
    navigator.userAgent,
  );
}
