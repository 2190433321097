import cookies from 'browser-cookies';

export const setCookie = (name, value = {}) => cookies.set(name, value);

export const getCookie = name => cookies.get(name);

export const deleteCookie = name => cookies.erase(name);

export const saveXID = () => {
  const xid = getCookie('xid');
  const params = new URLSearchParams(window.location.search);
  const newXid = params.get('xid');
  if ((!xid && newXid) || (newXid && newXid !== xid)) {
    setCookie('xid', newXid);
  }
  return xid;
};
