import { http } from 'src/apps/product-detail/utils/http';

class OrderAPI {
  getOrder = (opts = {}) => {
    const { orderNumber } = opts;
    return http.get(`/api/order/${orderNumber}`, {}).then(r => r.data);
  };
}

export const orderAPI = new OrderAPI();
