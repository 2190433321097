import { http } from 'src/apps/product-detail/utils/http';

class AutoCompleteAPI {
  getPostInfoByZip = (opts = {}) => {
    const { zip } = opts;
    return http.get(`/api/autocomplete/postal/zip`, { zip }).then(r => r.data);
  };
}

export const autoCompleteAPI = new AutoCompleteAPI();
