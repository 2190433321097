export * from './decl-of-num';
export * from './load-script';
export * from './is-mobile';
export * from './is-in-viewport';
export * from './get-yandex-map-api-url';
export * from './get-backend-data';
export * from './mapper';
export * from './regexp';
export * from './clearPhoneNumber';
export * from './eventBus';
export * from './getSwipe';
export * from './get-attributes';
export * from './cdn';
export * from './callUsButtonClick';
export * from './id-for-analytics';
export * from './number-declination';
export * from './adjust-tooltip-position';
