import { http } from 'src/apps/product-detail/utils/http';

class PaymentAPI {
  getMethods = () => {
    const payload = {
      filter: 'PhoneOrdering,SberBank',
    };

    return http.get('/api/payment/methods', payload).then(r => r.data);
  };
}

export const paymentAPI = new PaymentAPI();
