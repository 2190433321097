import { http } from 'src/apps/product-detail/utils/http';

class ProfileAPI {
  getProfile = () => {
    return http.get('/api/profile').then(r => r.data);
  };

  setProfile = (opts = {}) => {
    const { params, xid } = opts;
    return http.patch('/api/profile', params, { headers: { xid } }).then(r => r.data);
  };

  setRegAuth = (opts = {}) => {
    const { id } = opts;
    return http.post(`/api/contexts/${id}/billing/regUser`, { regUser: true });
  };
}

export const profileAPI = new ProfileAPI();
