import Vue from 'vue';

export default {
  state: () => ({}),
  mutations: {},
  actions: {
    generateSms({ commit }, params) {
      commit('setLoading', true);
      return new Promise((resolve, reject) => {
        Vue.$http
          .post('/api/sms/generate', params)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          })
          .finally(() => {
            commit('setLoading', false);
          });
      });
    },
    verifyPhone({ commit, dispatch }, params) {
      commit('setLoading', true);
      return new Promise((resolve, reject) => {
        Vue.$http
          .post('/api/sms/verify', params)
          .then(response => {
            const query = {
              phone: params.phone,
              hash: response.data.hash,
              phoneCountryCode: 'RU',
            };
            dispatch('setUserBillingPhone', query)
              .then(resp => {
                resolve(resp);
              })
              .catch(error => {
                reject(error);
              });
          })
          .catch(error => {
            reject(error);
          })
          .finally(() => {
            commit('setLoading', false);
          });
      });
    },
  },
  getters: {
    // @see config/sms.php параметр otp_sms_autodiscover
    otpSmsAutodiscover() {
      return Boolean(window?.__X_CART_DATA__?.otpSmsAutodiscover);
    },
  },
};
