class Init {
  result = null;

  start(opts = {}) {
    const { store } = opts;

    if (this.result) {
      return;
    }

    this.result = new Promise(resolve => {
      store.dispatch('profile/init').then(() => {
        resolve(Promise.all([store.dispatch('cart/init'), store.dispatch('myAcuvue/init')]));
      });
    });
  }

  async wait() {
    await this.result;
  }
}

export const init = new Init();
