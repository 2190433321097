/**
 * Валидация по номеру телефона
 *
 * @param phone {string}
 * @returns {boolean}
 */
export function isPhoneRegexp(phone) {
  return /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){11}(\s*)?$/.test(phone);
}

/**
 * Валидация по e-mail
 *
 * @param email {string}
 * @returns {boolean}
 */
export function isEmailRegexp(email) {
  const req = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return req.test(email);
}
