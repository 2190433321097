/* eslint-disable no-console */
export const sendAnalytics = (event, params = {}) => {
  const { gtag, yaCounter23619250 } = window;

  if (gtag === undefined || typeof gtag !== 'function') {
    console.warn('Gtag is not initialized');
  } else {
    gtag('event', event, params);
  }

  if (yaCounter23619250 === undefined || typeof yaCounter23619250 !== 'object') {
    console.warn('Yandex Metrika is not initialized');
  } else {
    yaCounter23619250.reachGoal(event);
  }
};

const adaptedItems = items =>
  items.map(({ productId, name, price, amount, idForAnalytics }) => ({
    id: productId,
    name,
    price,
    quantity: amount,
    idForAnalytics: idForAnalytics[0],
  }));

export const onProductClick = data => {
  if (data.product && data.position) {
    const { product, position } = data;
    const params = {
      name: product.name,
      id: product.id,
      price: product.price,
      brand: product.brandName,
      category: product.categoryName,
      variant: product.variant ? product.variant : '',
      position,
      idForAnalytics: product.idForAnalytics,
      url: product.url,
    };
    window.xcore.trigger('analyticsProductClick', params);
  }
};

export const onRemoveItem = data => {
  const { productId, name, amount, total, sku, price, categories, brand, variantId, idForAnalytics } = data;
  window.xcore.trigger('analyticsRemoveItemFromCart', {
    product: {
      currency: 'RUB',
      category: categories.length > 0 ? categories[0] : '',
      id: productId,
      name,
      sku,
      price,
      salePrice: total,
      quantity: amount,
      brand: brand || '',
      variant: variantId || '',
      idForAnalytics: idForAnalytics || '',
    },
  });
};

export const onProductsMounted = data => {
  const payload = {
    items: data.map(item => ({
      name: item.name,
      id: item.productId,
      price: item.price,
      brand: item.brand ? item.brand : '',
      category: item.categories.length > 0 ? item.categories[0] : '',
      variant: item.variantId ? item.variantId : '',
      quantity: item.amount,
      total: item.total,
      idForAnalytics: item.idForAnalytics ? item.idForAnalytics : '',
    })),
    url: window.location.href,
  };
  window.xcore.trigger('analyticsBeginCheckout', payload);
};

export const onBuyOneClick = products => {
  sendAnalytics('buy_oneclick', { items: adaptedItems(products) });
};

export const onPointSelect = type => {
  sendAnalytics(`delivery_point_${type}`, {
    category: 'delivery',
    action: 'point',
    label: type,
  });
};
