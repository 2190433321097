import { profileAPI } from 'src/api';

export const profile = {
  namespaced: true,

  state: () => ({
    inited: false,
    fields: {},
  }),

  getters: {
    inited: state => state.inited,
    fields: state => state.fields,
  },

  actions: {
    async init({ dispatch }) {
      await dispatch('fetchProfile');
    },

    async fetchProfile({ commit }) {
      const fields = await profileAPI.getProfile();
      commit('setFields', fields);
      commit('setInited', true);
    },

    async setProfile({ dispatch, getters }, params) {
      const { xid } = getters.fields;
      await profileAPI.setProfile({ params, xid });
      dispatch('fetchProfile');
    },
  },

  mutations: {
    setInited(state, inited) {
      state.inited = inited;
    },

    setFields(state, fields) {
      state.fields = fields;
    },

    setField(state, { key, value }) {
      state.fields[key] = value;
    },
  },
};
