import { http } from 'src/apps/product-detail/utils/http';

class CartAPI {
  getCart = (opts = {}) => {
    const { xid } = opts;

    return http.get(`/api/cart/items`, { xid }).then(r => r.data);
  };

  addItem = (opts = {}) => {
    const { xid, items } = opts;

    return http.post('/api/cart/items', { items }, { headers: { xid } }).then(r => r.data);
  };

  deleteItems = (opts = {}) => {
    const { xid, items } = opts;

    return http.delete('/api/cart/items', { items }, { headers: { xid } }).then(r => r.data);
  };
}

export const cartAPI = new CartAPI();
