import { http } from 'src/apps/product-detail/utils/http';

class GeoAPI {
  cities(params = {}, opts = {}) {
    const { q = '', mode = 'none', c = 'RU' } = params;

    return http.get(`/api/geo/cities`, { q, mode, c }, opts).then(r => r.data);
  }

  citiesPresence() {
    return http.get('/api/geo/cities/presence').then(r => r.data);
  }
}

export const geoAPI = new GeoAPI();
