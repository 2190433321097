import Vuex from 'vuex';
import { profile } from './store/profile';
import { cart } from './store/cart';
import { myAcuvue } from './store/myAcuvue';
import { common } from './store/common';
import { checkout } from './checkout/store';
import { auth } from './store/auth';

export async function createStore(state) {
  const store = new Vuex.Store({
    state: () => ({
      ...state,
      htmlCacheTags: [],
    }),

    mutations: {
      addHtmlCacheTags(s, v) {
        s.htmlCacheTags.push(v);
      },
    },
  });

  store.registerModule('profile', profile, { preserveState: typeof window !== 'undefined' });
  store.registerModule('myAcuvue', myAcuvue, { preserveState: typeof window !== 'undefined' });
  store.registerModule('cart', cart, { preserveState: typeof window !== 'undefined' });
  store.registerModule('common', common, { preserveState: typeof window !== 'undefined' });
  store.registerModule('checkout', checkout, { preserveState: typeof window !== 'undefined' });
  store.registerModule('auth', auth, { preserveState: typeof window !== 'undefined' });

  return store;
}
