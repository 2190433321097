import { http } from 'src/apps/product-detail/utils/http';

class FormsAPI {
  callUs(opts = {}) {
    return http.post('/api/forms/call-us', opts).then(r => r.data);
  }

  buyOneClick(opts = {}) {
    return http.post('/api/forms/buy-one-click', opts).then(r => r.data);
  }
}

export const formsAPI = new FormsAPI();
