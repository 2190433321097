import { getCookie, setCookie } from 'src/utils/cookies';
import { onRemoveItem } from 'src/utils/analytics';
import { idForAnalytics } from 'src/utils';
import { cartAPI } from 'src/api/cart';
import { contextAPI } from 'src/api/context';
import Vue from 'vue';
import shared from './store/shared';
import shippings from './store/shippings';
import geo from './store/geo';
import billings from './store/billings';
import sms from './store/sms';

let сancelTokenSource;
export const CART_STATE_GETTING = 1;
export const CART_STATE_UPDATING = 2;
export const checkout = {
  namespaced: true,

  modules: {
    shared,
    shippings,
    geo,
    billings,
    sms,
  },
  state: () => ({
    context: {},
    contextItems: [],
    currentUser: {},
    paymentMethods: [],
    currentPaymentMethod: {},
    errorMessage: '',
    wasAuthRequest: false,
    loading: false,
    postInfo: {},
    hits: [],
    currentUserPosition: null,
    isSubscribed: true,
  }),
  getters: {
    isLogged: state => state.context.isLogged,
    getContext(state) {
      return state.context;
    },
    getContextItems(state) {
      return state.contextItems;
    },
    getDisabledItems(state) {
      return state.contextItems.filter(item => item.enabled === false);
    },
    getEnabledItems(state) {
      return state.contextItems.filter(item => item.enabled === true);
    },
    getTotalSum(state) {
      const contextItemsPrice = state.contextItems.reduce((acc, item) => {
        return item.price * item.amount + acc;
      }, 0);

      return state.context?.shipping?.total
        ? state.context?.total + state.context?.shipping?.total
        : state.context?.total || contextItemsPrice;
    },
    getMyAcuvuePromotion(state) {
      if (state.context.promotion) {
        return state.context.promotion.find(({ code }) => code === 'MyAcuvue');
      }
      return false;
    },
    isCartEmpty(state) {
      return !state.contextItems.length;
    },
    allProductsAreUnavailable(state) {
      const avalibleItems = state.contextItems.filter(item => item.enabled);
      if (!state.shared.deletedItems.length && avalibleItems.length) {
        return false;
      }
      return !avalibleItems.filter(item => {
        return !state.shared.deletedItems.find(deletedItem => deletedItem.itemId === item.itemId);
      }).length;
    },
  },
  mutations: {
    setContext(state, { fields }) {
      Object.keys(fields).forEach(key => {
        Vue.set(state.context, key, fields[key]);
      });
    },
    setContextItems(state, { items }) {
      state.contextItems.splice(0, state.contextItems.length, ...items);
    },
    deleteContextItem(state, id) {
      const index = state.contextItems.findIndex(item => item.itemId === id);
      if (index !== -1) {
        state.contextItems.splice(index, 1);
      }
    },
    setCurrentUser: (state, { currentUser }) => {
      Object.keys(currentUser).forEach(key => {
        Vue.set(state.currentUser, key, currentUser[key]);
      });
    },
    setErrorMessage: (state, errorMessage) => {
      globalThis.emitter.emit('addNotification', errorMessage);
    },
    setWasAuthRequest: (state, wasAuthRequest) => {
      Vue.set(state, 'wasAuthRequest', wasAuthRequest);
    },
    setLoading: (state, loading) => {
      globalThis.emitter.emit('showPreloader', loading);
    },
    setPostInfo(state, { fields }) {
      if (fields.address) {
        Object.keys(fields).forEach(key => {
          Vue.set(state.postInfo, key, fields[key]);
        });
      } else {
        Vue.set(state, 'postInfo', {});
      }
    },
    setHits(state, { hits }) {
      state.hits.splice(0, state.hits.length, ...hits.slice(0, 4));
    },
    setPaymentMethods(state, { methods }) {
      state.paymentMethods.splice(0, state.paymentMethods.length, ...methods);
    },
    setCurrentPaymentMethod(state, method) {
      if (method.length === 0) {
        const current = state.paymentMethods.find(payment => payment.serviceName === 'SberBank');
        Vue.set(state, 'currentPaymentMethod', current || {});
      } else {
        const current = state.paymentMethods.find(payment => payment.methodId === method.paymentMethod);
        Vue.set(state, 'currentPaymentMethod', current || {});
      }
    },
    setCurrentUserPosition: (state, currentUserPosition) => {
      Vue.set(state, 'currentUserPosition', currentUserPosition);
    },
    setIsSubscribed: (state, isSubscribed) => {
      Vue.set(state, 'isSubscribed', isSubscribed);
    },
  },
  actions: {
    /**
     * Инициализация контекста
     *
     * @param {Object} arg1 Аргумент 1
     * @param {Object} arg1.state Состояние
     * @param {Function} arg1.commit Коммит
     * @param {Function} arg1.dispatch Отправка
     * @param {Function} arg1.getters Геттеры
     * @param {Object} [options={}] Параметры
     * @param {Boolean|undefined} options.isCreateContext Опционально. По умолчанию false. Требуется ли создание контекста
     * @param {Integer|undefined} options.cartState Опционально. По умолчанию CART_STATE_UPDATING. Состояние получения корзины
     * @param {Boolean|undefined} options.simpleContext Опционально. По умолчанию false. Упрощенная версия контекста (для скорости выборки)
     * @return {Promise}
     */
    async bootstrap({ state, commit, dispatch, getters }, options = {}) {
      const { isCreateContext = false, cartState = CART_STATE_UPDATING, simpleContext = false } = options;
      commit('setLoading', true);
      try {
        if (isCreateContext) {
          await dispatch('setContext', { cartState, simpleContext });
        } else {
          await dispatch('getContext', { cartState, simpleContext });
        }

        const { context } = state;

        if (context) {
          setCookie('stateId', context.id);

          commit('setContext', { fields: context });
          if (context.city && context.city.coordinates.lat && !context.shipping.type) {
            commit('setMapCoords', [context.city.coordinates.lat, context.city.coordinates.lng]);
            commit('setMapZoom', getters.constantZoomCity);
          }
          if (context.shipping && context.shipping.optician) {
            commit('setChangedPickupPoint', context.shipping.optician);
          }

          await dispatch('getItems');

          const deletedItems = JSON.parse(window.localStorage.getItem('deletedItems')) || [];
          if (Array.isArray(deletedItems) && deletedItems.length > 0) {
            commit('setDeletedItems', deletedItems);
          }
        }
      } catch (e) {
        if (!e.response?.data?.message) {
          commit('setErrorMessage', 'Произошла ошибка');
        } else {
          commit('setErrorMessage', e.response.data.message);
        }
      } finally {
        commit('setLoading', false);
      }
    },

    async changeAmount({ state, commit, dispatch }, { id, amount, cartState, simpleContext }) {
      commit('setLoading', true);
      try {
        await Vue.$http.patch(`/api/contexts/${state.context.id}/items/${id}`, { type: 'amount', amount });
      } finally {
        commit('setLoading', false);
        await dispatch('bootstrap', { cartState, simpleContext });
      }
    },

    async applyAcuvueDiscount({ state, commit }, { code }) {
      commit('setLoading', true);
      try {
        await Vue.$http.post(`/api/contexts/${state.context.id}/promotion/voucher/${code}`);
      } finally {
        commit('setLoading', false);
      }
    },

    async deleteAcuvueDiscount({ state, commit }, { code }) {
      commit('setLoading', true);
      try {
        await Vue.$http.delete(`/api/contexts/${state.context.id}/promotion/voucher/${code}`);
      } finally {
        commit('setLoading', false);
      }
    },

    async addItem({ commit, dispatch }, payload) {
      commit('setLoading', true);
      const { cartState, simpleContext } = payload;
      try {
        await contextAPI.addItem(payload);
      } catch (e) {
        console.error(e);
        /* eslint-disable camelcase */
        const msg = e.response?.data?.reason_phrase || e.response?.data?.message || 'Произошла ошибка';
        commit('setErrorMessage', msg);
      } finally {
        commit('setLoading', false);
        await dispatch('bootstrap', { cartState, simpleContext });
      }
    },

    async deleteItem({ state, commit, dispatch }, itemId) {
      commit('setLoading', true);
      try {
        const payload = {
          contextId: state.context.id,
          itemId,
        };
        await contextAPI.deleteItem(payload);
      } catch (e) {
        console.error(e);
        /* eslint-disable camelcase */
        const msg = e.response?.data?.reason_phrase || e.response?.data?.message || 'Произошла ошибка';
        commit('setErrorMessage', msg);
      } finally {
        commit('setLoading', false);
        await dispatch('bootstrap');
      }
    },

    async addDifferentLenses({ state, commit, dispatch }, payload) {
      commit('setLoading', true);
      try {
        await Vue.$http.post(`/api/contexts/${state.context.id}/items/collection`, { items: payload });
      } finally {
        commit('setLoading', false);
        await dispatch('bootstrap');
      }
    },

    async changeAmountDifferentLenses({ state, commit, dispatch }, payload) {
      commit('setLoading', true);
      try {
        await Vue.$http.patch(`/api/contexts/${state.context.id}/items/collection`, { items: payload });
      } finally {
        commit('setLoading', false);
        await dispatch('bootstrap');
      }
    },

    // eslint-disable-next-line no-unused-vars
    async deleteItems({ commit, rootState, dispatch }, opts = {}) {
      const { items } = opts;

      // TODO: возможно некорректное поведение
      // Ручка просит xid, но с ним она крашит. Без xid нормально отрабатывает (Скорее всего xid берётся из cookies)
      // const { id: xid } = rootState.context;

      try {
        commit('setLoading', true);

        const payload = {
          // xid,
          items: items.map(item => ({ productId: item.productId, variantId: item.variantId })),
        };

        await cartAPI.deleteItems(payload);

        items.forEach(item => {
          const obj = {
            ...item,
            idForAnalytics: idForAnalytics(item.productId, [item.variantId]),
          };
          onRemoveItem(obj);

          commit('deleteContextItem', item.itemId);
          commit('addDeletedItem', item);
        });
      } catch (e) {
        console.error(e);
        /* eslint-disable camelcase */
        const msg = e.response?.data?.reason_phrase || e.response?.data?.message || 'Произошла ошибка';
        commit('setErrorMessage', msg);
      } finally {
        commit('setLoading', false);
      }

      try {
        await dispatch('getContext');
      } catch (e) {
        console.error(e);
      }
    },

    async getItems({ state, commit }) {
      try {
        const items = await Vue.$http.get(`/api/contexts/${state.context.id}/items`);
        commit('setContextItems', { items: items.data });
      } catch (e) {
        if (!e.response.data.message) {
          commit('setErrorMessage', 'Произошла ошибка');
        } else {
          commit('setErrorMessage', e.response.data.message);
        }
      }
    },

    async getContext({ commit, dispatch }, options = {}) {
      commit('setLoading', true);
      const { cartState = CART_STATE_UPDATING, simpleContext = false } = options;
      if (сancelTokenSource) {
        сancelTokenSource.cancel();
      }
      сancelTokenSource = Vue.$http.CancelToken.source();

      try {
        const stateId = getCookie('stateId');
        if (stateId) {
          const context = await Vue.$http.get(`/api/contexts/${stateId}?cs=${cartState}&cbp[simple]=${simpleContext}`, {
            cancelToken: сancelTokenSource.token,
          });
          commit('setContext', { fields: context.data });
        } else {
          throw new Error('stateId has no value');
        }
      } catch (e) {
        await dispatch('setContext');
      } finally {
        commit('setLoading', false);
      }
    },

    async setContext({ commit }, options = {}) {
      const { cartState = CART_STATE_UPDATING, simpleContext = false } = options;

      try {
        const context = await Vue.$http.post(`/api/contexts?cs=${cartState}&cbp[simple]=${simpleContext}`);
        commit('setContext', { fields: context.data });
      } catch (e) {
        if (!e.response?.data?.message) {
          commit('setErrorMessage', 'Произошла ошибка');
        } else {
          commit('setErrorMessage', e.response.data.message);
        }
      }
    },

    async getHits(state) {
      state.commit('setLoading', true);
      try {
        const hits = await Vue.$http.get('/api/products/hits');
        state.commit('setHits', { hits: hits.data });
      } catch (e) {
        state.commit('setErrorMessage', e.response.data.message);
      } finally {
        state.commit('setLoading', false);
      }
    },
    async setPayment({ state, commit }, { method }) {
      commit('setLoading', true);
      try {
        await Vue.$http.patch(`/api/contexts/${state.context.id}/payment`, {
          methodId: method.methodId,
        });
      } catch (e) {
        commit('setErrorMessage', e.response.data.message);
      } finally {
        commit('setLoading', false);
      }
    },
    async getPaymentMethods({ state, commit }) {
      commit('setLoading', true);
      try {
        const paymentTypes = await Vue.$http.get(`/api/contexts/${state.context.id}/payment/methods`);
        commit('setPaymentMethods', { methods: paymentTypes.data });
      } catch (e) {
        console.error(e);
        commit('setErrorMessage', e.response.data.message);
      } finally {
        commit('setLoading', false);
      }
    },
    async getCurrentPayment({ state, commit, dispatch }, opts = {}) {
      const { click = false } = opts;

      try {
        commit('setLoading', true);

        const { data: currentPayment } = await Vue.$http.get(`/api/contexts/${state.context.id}/payment`);
        const { currentPaymentMethod: oldPaymentMethod } = state;

        if (currentPayment && !(currentPayment instanceof Array)) {
          const hasCurrentMethod = state.paymentMethods.find(m => m.methodId === currentPayment.paymentMethod);

          if (!hasCurrentMethod) {
            globalThis.emitter.emit('addNotification', 'Изменился способ оплаты');

            if (state.paymentMethods.length) {
              await dispatch('setPayment', { method: state.paymentMethods[0] });
              return dispatch('getCurrentPayment');
            }
          } else if (
            typeof oldPaymentMethod.methodId === 'number' &&
            oldPaymentMethod.methodId !== currentPayment.paymentMethod &&
            !click
          ) {
            globalThis.emitter.emit('addNotification', 'Изменился способ оплаты');
          }
        }

        commit('setCurrentPaymentMethod', currentPayment);
      } catch (e) {
        console.error(e);
        commit('setErrorMessage', e.response.data.message);
      } finally {
        commit('setLoading', false);
      }
    },

    async finish({ state, commit }, { isSubscribed }) {
      try {
        commit('setLoading', true);
        const response = await Vue.$http.post(`/api/contexts/${state.context.id}/finish`, { isSubscribed });
        return response;
      } catch (error) {
        console.error(error);
        if (error.response.data.code !== 4009) {
          throw error;
        }
      } finally {
        commit('setLoading', false);
      }
    },
  },
};
