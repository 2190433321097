import { http } from 'src/apps/product-detail/utils/http';

class RedirectAPI {
  checkRedirect = (opts = {}) => {
    const { path } = opts;

    return http.get(`/api/redirect`, { url: path });
  };
}

export const redirectAPI = new RedirectAPI();
