import { cartAPI } from 'src/api';

export const cart = {
  namespaced: true,

  state: () => ({
    inited: false,
    values: [],
  }),

  getters: {
    inited: state => state.inited,
    values: state => state.values,
  },

  actions: {
    async init({ dispatch }) {
      await dispatch('fetchCart');
    },

    async fetchCart({ commit, rootGetters }) {
      try {
        const { xid } = rootGetters['profile/fields'] || {};
        const values = await cartAPI.getCart({ xid });

        commit('setValues', values);
        commit('setInited', true);
      } catch (e) {
        console.error(e);
      }
    },

    setCart({ commit }, value) {
      commit('setValues', value);
    },
  },

  mutations: {
    setInited(state, inited) {
      state.inited = inited;
    },

    setValues(state, values) {
      state.values = values;
    },
  },
};
