import Vue from 'vue';
import { ERROR_CODE } from 'src/constants';

export default {
  state: {
    shippingMethods: [],
    shippingDates: [],
    nearestShippingDates: '',
    nearestPostDates: {},
    shippingData: [],
    shippingCost: null,
    currentShippingData: {},
    tempCourierComment: '',
    warningAddress: '',
    warningText:
      'Определение координат введенного адреса может быть неточным, проверьте правильность указанного адреса',
    messageAboutMissingDeliveryDates: '',
  },
  mutations: {
    setShippingMethods(state, payload) {
      state.shippingMethods = payload;
    },
    setShippingDates(state, payload) {
      state.shippingDates = payload;
    },
    setNearestShippingDates(state, payload) {
      state.nearestShippingDates = payload;
    },
    setNearestPostDates(state, payload) {
      state.nearestPostDates = payload;
    },
    setShippingData(state, payload) {
      state.shippingData = payload;
    },
    setShippingCost(state, payload) {
      state.shippingCost = payload;
    },
    setShippingApartment(state, payload) {
      if (state.shippingData.address) {
        state.shippingData.address.apartment = payload;
      }
    },
    setCurrentShippingData(state, payload) {
      state.currentShippingData = payload;
    },
    setTempCourierComment(state, payload) {
      state.tempCourierComment = payload;
    },
    setWarningAddress(state, payload) {
      state.warningAddress = payload;
    },
    setMessageAboutMissingDeliveryDates: (state, errorMessage) => {
      state.messageAboutMissingDeliveryDates = errorMessage;
    },
  },
  actions: {
    async loadShippingMethods({ getters, commit }) {
      await Vue.$http.get(`/api/contexts/${getters.getContext.id}/shipping/methods`).then(shippingMethods => {
        commit('setShippingMethods', shippingMethods.data);
      });
    },
    setShippingData({ getters, commit }, params) {
      return new Promise((resolve, reject) => {
        const response = Vue.$http
          .patch(`/api/contexts/${getters.getContext.id}/shipping/data`, params)
          .then(data => {
            commit('setCurrentShippingData', params.address);
            if (data?.data?.error) {
              commit('setMessageAboutMissingDeliveryDates', data?.data?.error?.message);
            }
            resolve();
          })
          .catch(err => {
            if (err.response.data.code === ERROR_CODE.deliveryError) {
              globalThis.emitter.emit('setErrorDeliveryMethod', true);
            }
            reject(err);
          });
        if (response && response.data && response.data.changedPrices) {
          commit('setShowPriceChangeAlert', true);
        }
      });
    },
    async loadShippingDates({ getters, commit }) {
      await Vue.$http
        .get(`/api/contexts/${getters.getContext.id}/shipping/dates`)
        .then(shippingDates => {
          commit('setShippingDates', shippingDates?.data || {});
          commit('setMessageAboutMissingDeliveryDates', '');
        })
        .catch(error => {
          if (
            error.response.status === ERROR_CODE.serviceUnavailable &&
            error.response.data.code === ERROR_CODE.error1cUnavailable
          ) {
            commit('setMessageAboutMissingDeliveryDates', error?.response?.data?.message);
          }
          commit('setShippingDates', []);
        });
    },
    async loadShippingCost({ getters, commit }) {
      await Vue.$http
        .get(`/api/contexts/${getters.getContext.id}/shipping/cost`)
        .then(shippingCost => {
          commit('setShippingCost', shippingCost?.data || 0);
        })
        .catch(() => {
          commit('setShippingCost', 0);
        });
    },
    async loadShippingData({ state, getters, commit }) {
      await Vue.$http
        .get(`/api/contexts/${getters.getContext.id}/shipping/data`)
        .then(shippingData => {
          if (shippingData.data && shippingData.data.address && shippingData.data.address.coords) {
            commit('setMapCoords', [shippingData.data.address.coords.lat, shippingData.data.address.coords.lon]);
            commit('setMapZoom', 18);
          }

          const oldDate = state.shippingData?.date;
          const newDate = shippingData.data?.date;
          if (oldDate !== newDate && newDate && oldDate) {
            globalThis.emitter.emit(
              'addNotification',
              'Ранее выбранная дата доставки недоступна. Выберите новую дату получения заказа.',
            );
          }
          commit('setShippingData', shippingData.data);
        })
        .catch(() => {
          commit('setShippingData', []);
        });
    },
    getPointData({ getters, commit }, id) {
      commit('setLoading', true);
      return new Promise((resolve, reject) => {
        Vue.$http
          .get(`/api/contexts/${getters.getContext.id}/shipping/points/${id}`)
          .then(response => {
            resolve(response.data);
            if (response?.data?.error) {
              commit('setMessageAboutMissingDeliveryDates', response?.data?.error?.message);
            }
          })
          .catch(error => {
            reject(error);
          })
          .finally(() => {
            commit('setLoading', false);
          });
      });
    },
    getNearestPoint({ getters, commit }, coordsPosition) {
      return new Promise((resolve, reject) => {
        Vue.$http
          .get(
            `/api/contexts/${getters.getContext.id}/shipping/nearestpoint?latitude=${coordsPosition.latitude}&longitude=${coordsPosition.longitude}`,
          )
          .then(response => {
            if (response?.data?.error) {
              commit('setMessageAboutMissingDeliveryDates', response?.data?.error?.message);
            }
            resolve(response.data[Object.keys(response.data)]);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getPointCost({ getters, commit }, id) {
      return new Promise((resolve, reject) => {
        Vue.$http
          .get(`/api/contexts/${getters.getContext.id}/shipping/points/${id}/cost`)
          .then(response => {
            commit('setShippingCost', response?.data || 0);
            resolve(response?.data || 0);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getPointDate({ getters, commit }, id) {
      commit('setLoading', true);
      return new Promise((resolve, reject) => {
        Vue.$http
          .get(`/api/contexts/${getters.getContext.id}/shipping/points/${id}/date`)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          })
          .finally(() => {
            commit('setLoading', false);
          });
      });
    },
    getCourierDate({ getters, commit }) {
      Vue.$http
        .get(`/api/contexts/${getters.getContext.id}/shipping/date/courier`)
        .then(response => {
          commit('setNearestShippingDates', response?.data);
        })
        .catch(error => {
          if (
            error.response.status === ERROR_CODE.serviceUnavailable &&
            error.response.data.code === ERROR_CODE.error1cUnavailable
          ) {
            commit('setMessageAboutMissingDeliveryDates', error?.response?.data?.message);
          }
        });
    },
    getPostDate({ getters, commit }, zip) {
      commit('setLoading', true);
      return new Promise((resolve, reject) => {
        Vue.$http
          .get(`/api/contexts/${getters.getContext.id}/shipping/date/post/${zip}`)
          .then(response => {
            commit('setNearestPostDates', response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          })
          .finally(() => {
            commit('setLoading', false);
          });
      });
    },
    getPostCost({ getters, commit }, zip) {
      commit('setLoading', true);
      return new Promise((resolve, reject) => {
        Vue.$http
          .get(`/api/contexts/${getters.getContext.id}/shipping/cost/post/${zip}`)
          .then(response => {
            commit('setShippingCost', response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          })
          .finally(() => {
            commit('setLoading', false);
          });
      });
    },
    async infoByZip(state, params) {
      try {
        const info = await Vue.$http.get('/api/autocomplete/postal/zip', {
          params,
        });
        state.commit('setPostInfo', { fields: info.data });
      } catch (e) {
        // state.commit('setErrorMessage', e.response.data.message);
      }
    },
  },
  getters: {
    shippingMethods(state) {
      return state.shippingMethods;
    },
    shippingDates(state) {
      return state.shippingDates;
    },
    shippingData(state) {
      return state.shippingData;
    },
    shippingCost(state) {
      return state.shippingCost;
    },
    availableDeliveryPath(state) {
      const allTypes = {
        crd: '/cart/delivery-params/',
        self: '/cart/pickup-params/',
        post: '/cart/post-params/',
      };
      if (state.shippingMethods.length) {
        return state.shippingMethods.map(method => allTypes[method.type]);
      }
      return [];
    },
    warningAddress(state) {
      return state.warningAddress;
    },
    priorityDeliveryPath(state, getters) {
      if (getters.availableDeliveryPath.length) {
        return getters.availableDeliveryPath[0];
      }
      return '/cart';
    },
  },
};
