export const AUTH_STEPS = {
  PHONE_CALL: 'phone_call',
  PHONE_CALL_CODE: 'phone_call_code',
  PHONE: 'phone',
  PHONE_CODE: 'phone_code',
  EMAIL: 'email',
  RECOVERY_EMAIL: 'recovery_email',
  RECOVERY_LINK_SENT: 'recovery_link_sent',
  RECOVERY_NEW_PASS: 'recovery_new_pass',
};
export const IS_CALL_REGISTRATION = 'regIsCall';
export const IS_PHONE_REGISTRATION = 'regPhone';
export const REG_START_TIME = 'regStartTime';

export const REPEATED_REQUEST_ERROR = {
  phone_call: 'Мы вам уже звонили. Введите 4 последние цифры пропущенного номера телефона.',
  phone: 'Код ранее направлен на указанный номер телефона. Проверьте SMS.',
};
