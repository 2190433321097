/*
 * Добавляет к числовым значениям склонение слова например '1 день'
 * @see        https://ru.stackoverflow.com/a/89511
 *
 * @param      {number}  n       Число
 * @param      {Array}   titles  Склонения массив из трех элементов
 *                              - например: ['день', 'дня', 'дней']
 * @return     {String}  Строка вида '1 день' или '2 дня'
 */
export default function numberDeclination(n, titles) {
  const cases = [2, 0, 1, 1, 1, 2];
  const title = titles[n % 100 > 4 && n % 100 < 20 ? 2 : cases[Math.min(n % 10, 5)]];
  return `${n} ${title}`;
}
