import { http } from 'src/apps/product-detail/utils/http';

class ContentAPI {
  specialOffers(params = {}) {
    const { select, limit = 99, offset = 0 } = params;

    const payload = { preset: 'main', limit, offset };

    if (select instanceof Array) {
      payload.select = select.join(',');
    }

    return http.get('/api/content/special-offers', payload).then(r => r.data);
  }

  getBlogPosts(params = {}) {
    const { cityId = 1, limit = 1, select } = params;

    const payload = { cityId, limit };

    if (select instanceof Array) {
      payload.select = select.join(',');
    }

    return http.get('/api/content/blog-posts', payload).then(r => r.data);
  }

  getNews(params = {}) {
    const { limit = 1, select } = params;

    const payload = { limit };

    if (select instanceof Array) {
      payload.select = select.join(',');
    }

    return http.get('/api/content/news', payload).then(r => r.data);
  }

  getBrands(params = {}) {
    const { limit = 4, select, preset = 'main' } = params;

    const payload = { preset, limit };

    if (select instanceof Array) {
      payload.select = select.join(',');
    }

    return http.get('/api/content/brands', payload).then(r => r.data);
  }

  getPromotions(params = {}) {
    const { cityId = 1, limit = 1, preset, select } = params;

    const validPresetValues = ['main', 'slider'];

    if (!validPresetValues.includes(preset)) {
      return Promise.reject(new Error(`Please specify "preset" param`));
    }

    const payload = { preset, cityId, limit };

    if (select instanceof Array) {
      payload.select = select.join(',');
    }

    return http.get('/api/content/promotions', payload).then(r => r.data);
  }

  getDetails(params = {}) {
    const { cityId, cleanUrl, select } = params;

    const payload = { cityId, cleanUrl };

    if (select instanceof Array) {
      payload.select = select.join(',');
    }

    return http.get('/api/content/details', payload).then(r => r.data);
  }
}

export const contentAPI = new ContentAPI();
