import { http } from 'src/apps/product-detail/utils/http';

class CacheAPI {
  /**
   *
   * @returns {Array<{id: string, name: string}>}
   */
  getTypes = () => {
    return http.get('/api/cache/types').then(r => r.data);
  };

  /**
   *
   * @param {{type: string}} opts
   * @returns {Array<{id: string, type: "input", label: string}>}
   */
  getOptions = (opts = {}) => {
    const { type } = opts;

    return http.get(`/api/cache/options?type=${type}`).then(r => r.data);
  };

  /**
   *
   * @param {{type: string, options: object}} opts
   */
  reset = (opts = {}) => {
    const { type, options = {} } = opts;

    return http.post(`/api/cache/reset`, { type, options }).then(r => r.data);
  };
}

export const cacheAPI = new CacheAPI();
