import { BACKEND_DATA_VARIABLE_NAME } from 'src/constants';

/**
 *  @returns {{
 *    currentCity: {
 *      region: number,
 *      cityId: number,
 *      countryCode: string,
 *      country: number,
 *      phoneMask: string
 *    },
 *    target: string,
 *    yandexMapApiUrl: string
 *  }}
 */
export function getBackendData() {
  const data = window[BACKEND_DATA_VARIABLE_NAME];

  if (!data) {
    throw new Error('Backend data is not defined');
  }

  return data;
}
