import { http } from 'src/apps/product-detail/utils/http';

class BannerAPI {
  getBanner = (opts = {}) => {
    const { place = '', position = 1, provider = 'common', params = {} } = opts;

    let options = '';
    if (params.options) {
      options = Object.keys(params.options)
        .map(key => {
          return `${key}=${params.options[key]}`;
        })
        .join(',');
    }

    return http
      .get(`/api/banners/${place}/provider=${provider},position=${position}`, {
        ...params,
        ...(options ? { options } : {}),
      })
      .then(r => r.data)
      .catch(() => {
        return {};
      });
  };
}

export const bannerAPI = new BannerAPI();
