import Vue from 'vue';

let сancelTokenSource;

export default {
  state: () => ({
    oneLineAddress: [],
    addressFromLocation: [],
    cities: [],
    metroList: [],
  }),
  mutations: {
    setOneLineAddress(state, payload) {
      state.oneLineAddress = payload;
    },
    setAddressFromLocation(state, payload) {
      state.addressFromLocation = payload;
    },
    setApartment(state, apartment) {
      if (state.addressFromLocation.length) {
        state.addressFromLocation[0].apartment = apartment;
      }
    },
    setCities(state, payload) {
      state.cities = payload;
    },
    setMetroList(state, payload) {
      state.metroList = payload;
    },
  },
  actions: {
    async loadOneLineAddress({ getters, commit }, params) {
      if (сancelTokenSource) {
        сancelTokenSource.cancel();
      }
      сancelTokenSource = Vue.$http.CancelToken.source();
      commit('setInnerLoading', true);
      const oneLineAddress = await Vue.$http.get(`/api/geo/cities/${getters.getContext.city.id}/oneLineAddress`, {
        params,
        cancelToken: сancelTokenSource.token,
      });
      commit('setOneLineAddress', oneLineAddress.data);
      commit('setInnerLoading', false);
    },
    async loadCities({ commit }, params) {
      if (сancelTokenSource) {
        сancelTokenSource.cancel();
      }
      сancelTokenSource = Vue.$http.CancelToken.source();
      const cities = await Vue.$http.get(`/api/geo/cities`, {
        params,
        cancelToken: сancelTokenSource.token,
      });
      commit('setCities', cities.data);
    },
    async changeCity({ commit, dispatch, getters }, id) {
      commit('setLoading', true);
      try {
        await Vue.$http.post(`/api/contexts/${getters.getContext.id}/geo/city`, null, { params: { cityId: id } });
        dispatch('bootstrap');
      } finally {
        commit('setLoading', false);
        commit('setShippingData', []);
      }
    },
    async loadAddressFromLocation({ commit, getters }, params) {
      if (сancelTokenSource) {
        сancelTokenSource.cancel();
      }
      сancelTokenSource = Vue.$http.CancelToken.source();
      try {
        commit('setLoading', true);
        const address = await Vue.$http.get(`/api/geo/cities/${getters.getContext.city.id}/oneLineAddress`, {
          params,
          cancelToken: сancelTokenSource.token,
        });
        if (address.data.length !== 0 && (address.data[0].street || address.data[0].house || address.data[0].body)) {
          commit('setAddressFromLocation', address.data);
        } else {
          commit('setAddressFromLocation', []);
        }
      } finally {
        commit('setLoading', false);
      }
    },
    async loadMetroList({ getters, commit }, id) {
      const cityId = !id ? getters.getContext.city.id : id;
      try {
        commit('setInnerLoading', true);
        await Vue.$http.get(`/api/geo/cities/${cityId}/metros`).then(response => {
          commit('setMetroList', response.data);
        });
      } finally {
        commit('setInnerLoading', false);
      }
    },
    async updateCartInfo({ dispatch }, id) {
      await dispatch('changeCity', id);
      await dispatch('loadMetroList', id);
      dispatch('loadShippingMethods');
    },
  },
  getters: {
    oneLineAddress(state) {
      return state.oneLineAddress;
    },
    addressFromLocation(state) {
      return state.addressFromLocation;
    },
    cities(state) {
      return state.cities;
    },
    metroList(state) {
      return state.metroList;
    },
  },
};
