import { http } from 'src/apps/product-detail/utils/http';

class MenuAPI {
  getMenu(opts = {}) {
    const { type } = opts;
    return http.get(`/api/menu/${type}`).then(r => r.data);
  }
}

export const menuAPI = new MenuAPI();
