import axios from 'axios';
import Vue from 'vue';
import Vuex from 'vuex';
import VueAxios from 'vue-axios';
import VueRouter from 'vue-router';
import { Plugin as Fragment } from 'vue-fragment';
import { cdn, getAttributes } from 'src/utils';
import { init } from 'src/apps/init';
import { createStore } from './store';
import { createRouter } from './router';

export { Vue, Vuex, VueRouter };

Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VueAxios, axios);
Vue.use(Fragment);

Vue.filter('cdn', cdn);

let createStorePromise;
let createRouterPromise;

export async function createClientApp(id, createApp) {
  if (typeof window !== 'undefined') {
    Vue.$http.defaults.withCredentials = true;

    document.addEventListener('DOMContentLoaded', async () => {
      const node = document.querySelector(`[vue-app-id="${id}"]`);
      const props = getAttributes(node);
      const state = window.__VUE_APP_STATE__;

      if (!createStorePromise) {
        createStorePromise = createStore(state);
      }

      if (!createRouterPromise) {
        createRouterPromise = Promise.resolve(createRouter());
      }

      const store = await createStorePromise;
      const router = await createRouterPromise;

      const { app, routes } = await createApp(store, router, props);

      if (app.$router) {
        if (routes) {
          routes().forEach(route => app.$router.addRoute(route));
        }
        await new Promise((resolve, reject) => app.$router.onReady(resolve, reject));
      }

      init.start({ store });

      app.$mount(node);
    });
  }
}
