import { http } from 'src/apps/product-detail/utils/http';

class AuthAPI {
  getMethod = () => {
    return http.get('/api/auth/available-methods').then(r => r.data);
  };

  getSocialMethods = () => {
    return http.get('/api/login/social/methods?state=cart').then(r => r.data);
  };

  generate = (opts = {}) => {
    const { type, login, captcha } = opts;
    return http.post(`/api/auth/generate?for=${type}`, { login, captcha }).then(r => r.data);
  };

  verify = (opts = {}) => {
    const { type, login, code, captcha } = opts;

    return http
      .post(`/api/auth/verify?for=${type}`, {
        login,
        code,
        captcha,
        noCartMerge: true,
      })
      .then(r => r.data);
  };

  login = (opts = {}) => {
    const { login, password, captcha } = opts;

    return http
      .post('/api/auth/login?for=email', {
        login,
        password,
        captcha,
      })
      .then(r => r.data);
  };

  restoreEmail = (opts = {}) => {
    const { login, captcha, target = '' } = opts;

    return http
      .post(`/api/auth/generate?for=email&type=recover&target=${target}`, { login, captcha })
      .then(r => r.data);
  };

  verifyEmail = (opts = {}) => {
    const { login, code, captcha, password, confirmPassword } = opts;

    return http
      .post('/api/auth/verify?for=email', {
        login,
        code,
        captcha,
        password,
        confirmPassword,
        noCartMerge: true,
      })
      .then(r => r.data);
  };
}

export const authAPI = new AuthAPI();
