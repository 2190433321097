import { REPEATED_REQUEST_ERROR } from 'src/constants';

// 2 minutes
const timer = 1000 * 60 * 2;

function clear() {
  localStorage.removeItem('regStartTime');
  localStorage.removeItem('regPhone');
  localStorage.removeItem('regIsCall');
}

function start(callback) {
  const regStartTime = Number(localStorage.getItem('regStartTime'));
  const regPhone = localStorage.getItem('regPhone');

  if (!regStartTime || !regPhone) {
    return clear();
  }

  const diff = Date.now() - regStartTime;
  const timeLeft = timer - diff;

  if (timeLeft <= 0) {
    return clear();
  }

  callback({ time: timeLeft, phone: regPhone });
}

function save(StorageFlag, value) {
  localStorage.setItem(StorageFlag, value);
}

function maybeNotify(has_sms = false, type) {
  const text = type === 'phone_call' ? REPEATED_REQUEST_ERROR.phone_call : REPEATED_REQUEST_ERROR.phone;
  /* eslint-disable camelcase */
  if (has_sms) {
    throw new Error(text);
  }
}

export { start, timer, clear, save, maybeNotify };
