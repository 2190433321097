/**
 * Маппер данных
 * для отсутствующих ключей подставляет undefined
 *
 * @param      {<type>}  data    Данные
 * @param      {<type>}  map     Карта
 * @return     {<type>}  размеченные данные
 */
export default function mapData(data, map) {
  const out = {};
  Object.keys(map).forEach(key => {
    out[key] = data[map[key]] || undefined;
  });
  return out;
}
