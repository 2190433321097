import { redirectAPI } from 'src/api';

export const common = {
  namespaced: true,

  state: () => ({
    redirect: '',
    isMobile: null,
    isTablet: null,
  }),

  getters: {
    redirect: state => state.redirect,
  },

  actions: {
    async checkRedirect({ commit }, params) {
      const { path } = params;
      try {
        const redirect = await redirectAPI.checkRedirect({ path });
        commit('setRedirect', redirect.data.url);
      } catch (e) {
        commit('setRedirect', '');
        console.warn(e);
        throw e;
      }
    },
  },

  mutations: {
    setRedirect(state, redirect) {
      state.redirect = redirect;
    },
    setIsMobile: (state, isMobile) => {
      state.isMobile = isMobile;
    },
    setIsTablet: (state, isTablet) => {
      state.isTablet = isTablet;
    },
  },
};
