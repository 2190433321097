import Vue from 'vue';

export default {
  state: () => ({
    userBillingInfo: {},
    userBillingInfoLoaded: false,
  }),
  mutations: {
    setUserBillingInfo(state, { fields }) {
      Object.keys(fields).forEach(key => {
        Vue.set(state.userBillingInfo, key, fields[key]);
      });
    },
    setUserBillingInfoLoaded(state, payload) {
      state.userBillingInfoLoaded = payload;
    },
  },
  actions: {
    async loadUserBillingInfo({ getters, commit }) {
      const userBillingInfo = await Vue.$http.get(`/api/contexts/${getters.getContext.id}/billing`);
      if (userBillingInfo.data.phone) {
        userBillingInfo.data.confirmed = true;
      }
      commit('setUserBillingInfo', { fields: userBillingInfo.data });
      commit('setUserBillingInfoLoaded', true);
    },
    async setUserBillingNotes({ getters }, params) {
      await Vue.$http.post(`/api/contexts/${getters.getContext.id}/billing/notes`, params);
    },
    setUserBillingPhone({ getters }, params) {
      return Vue.$http.post(`/api/contexts/${getters.getContext.id}/billing/phone`, params);
    },

    async setUserBillingName({ getters }, params) {
      await Vue.$http.post(`/api/contexts/${getters.getContext.id}/billing/user`, params);
    },
  },
  getters: {
    userBillingInfo(state) {
      return state.userBillingInfo;
    },
  },
};
