import { http } from 'src/apps/product-detail/utils/http';

class BusinessPromotionAPI {
  getPromotions = () => {
    return http.get(`/api/business-promotions`).then(r => r.data);
  };

  setPromotion = (opts = {}) => {
    const { promotionId, params } = opts;
    return http.patch(`/api/business-promotions/${promotionId}`, params).then(r => r.data);
  };
}

export const businessPromotionAPI = new BusinessPromotionAPI();
