import { getBackendData } from 'src/utils/get-backend-data';

export function getYandexMapApiUrl() {
  const { yandexMapApiUrl } = getBackendData();

  if (!yandexMapApiUrl) {
    throw new Error('yandexMapApiUrl is not defined');
  }

  return yandexMapApiUrl;
}
