import { http } from 'src/apps/product-detail/utils/http';

class MyAcuvueAPI {
  getBalance() {
    return http.get('/api/myacuvue/personal/balance').then(r => r.data);
  }

  getDiscounts() {
    return http.get('/api/myacuvue/personal/discounts').then(r => r.data);
  }

  getRecommends() {
    return http.get('/api/myacuvue/personal/recommends').then(r => r.data);
  }

  isLock() {
    return http.get('/api/myacuvue/personal/is_lock').then(r => r.data);
  }

  getPromoScenario() {
    return http.get('/api/myacuvue/personal/scenario').then(r => r.data);
  }
}

export const myAcuvueAPI = new MyAcuvueAPI();
