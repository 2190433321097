/**
 * Добавляет в документ скрипт с указынным адресом и атрибутами.
 * Возвращает промис, который резолвится на событие onload скрипта.
 *
 * @param url {string}
 * @param attributes {Object}
 * @returns {Promise<void>}
 */
export function loadScript(url = '', attributes = {}) {
  return new Promise((resolve, reject) => {
    if (!url) {
      reject(new Error('url param must be defined'));
      return;
    }

    const { async = true, defer = false, ...rest } = attributes;

    const script = document.createElement('script');

    Object.assign(script, rest);
    script.async = async;
    script.defer = defer;
    script.onload = resolve;
    script.onerror = reject;
    script.src = url;

    document.head.appendChild(script);
  });
}
