import { http } from 'src/apps/product-detail/utils/http';

class ProductsAPI {
  getProduct = (opts = {}) => {
    const { id, vid, select } = opts;

    let path = `/api/products/${id}`;

    if (vid) {
      path += `/${vid}`;
    }

    if (select) {
      path += `?select=${select.join(',')}`;
    }

    return http.get(path).then(r => r.data);
  };

  getProductTags = (opts = {}) => {
    const { id } = opts;

    return http.get(`/api/products/${id}/tags`).then(r => r.data);
  };

  getProductImages = (opts = {}) => {
    const { id } = opts;

    return http.get(`/api/products/${id}/images`).then(r => r.data);
  };

  getProductFeatures = (opts = {}) => {
    const { id } = opts;

    return http.get(`/api/products/${id}/features`).then(r => r.data);
  };

  getProductMedia = (opts = {}) => {
    const { id } = opts;

    return http.get(`/api/products/${id}/media`).then(r => r.data);
  };

  getProductAttributes = (opts = {}) => {
    const { id } = opts;

    return http.get(`/api/products/${id}/attributes`).then(r => r.data);
  };

  getProductOptions = (opts = {}) => {
    const { id, vid } = opts;
    const path = vid ? `/api/products/${id}/options/${vid}` : `/api/products/${id}/options`;

    return http.get(path).then(r => r.data);
  };

  getVariantId = (opts = {}) => {
    const { id, attributes = '' } = opts;

    return http
      .get(`/?target=productVariantsDisplay&action=getVariantsData&product_id=${id}${attributes}`)
      .then(r => r.data);
  };

  getRecommendedProducts = (opts = {}) => {
    const { id } = opts;

    return http.get(`/api/products/${id}/recommended`).then(r => r.data);
  };

  getProductHits = (params = {}) => {
    const { preset, select } = params;

    const payload = {};

    if (preset) {
      payload.preset = preset;
    }

    if (select instanceof Array) {
      payload.select = select.join(',');
    }

    return http.get(`/api/products/hits`, payload).then(r => r.data);
  };
}
export const productsAPI = new ProductsAPI();
